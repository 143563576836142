.error-page {
  cursor: default;
  a{
    margin-top: 50px;
  }
}

.error {
  height: 100vh;
}

.not-found {
  min-height: 400px;
}
